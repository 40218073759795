<script setup lang="ts">
import type { Collections } from '~/.directus/generated/client'
import PBRouter from '~/components/page-builder/PBRouter.vue'

const { t } = useI18n()
const route = useRoute()

// Fetch page data
const { data: page, error, status } = await usePageData('home')
// Fetch error
if (status.value === 'error') {
  throw error
}

// No data found
if (!page.value) {
  throw createError({
    statusCode: 404,
    statusMessage: t('error.not-found'),
    fatal: true,
  })
}

// Propagate translation slugs for lang switcher and hreflangs
// https://github.com/nuxt-modules/i18n/issues/1736
route.meta.nuxtI18n = page.value.translations?.reduce(
  (obj, translation) => ({
    ...obj,
    [translation.languages_code.toString().split('-')[0].toLowerCase()]: {
      slug: translation.slug,
    },
  }),
  {}
)

const content = page.value.currentSeo?.description ?? ''
const title = page.value.currentSeo?.title ?? page.value.title
// Set page meta data
useHead({
  title,
  meta: [
    {
      name: 'description',
      content,
    },
  ],
})
</script>

<template>
  <div>
    <PBRouter
      v-if="page?.Builder"
      :builder="page.Builder as Collections.PagesBuilder[]"
    />
  </div>
</template>
